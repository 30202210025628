import './layout.scss';
import Vue from 'vue';
import VueTableOfContent from '@ui-common/vue-table-of-content';
import macc from '../../modules/MACC/macc';
import mhap from '../../modules/MHAP/mhap.vue';
import msup from '../../modules/MSUP/msup';
import mjbl from '../../modules/MJBL/mjbl.vue';
import mstb from '../../modules/MSTB/mstb';
import masd from '../../modules/MASD/masd.vue';
import BranchAtmWidgetWrapper from '../../applications/branch-atm-widget-wrapper/branch-atm-widget-wrapper.vue';
import layoutBase from '../layout-base';

Vue.component('macc', macc);
Vue.component('mhap', mhap);
Vue.component('mjbl', mjbl);
Vue.component('mstb', mstb);
Vue.component('msup', msup);
Vue.component('masd', masd);
Vue.component('vue-table-of-content', VueTableOfContent);
Vue.component('branch-atm-widget-wrapper', BranchAtmWidgetWrapper);

/* eslint-disable no-new*/
new Vue({
	extends: layoutBase,
	el: '[data-layout-id=AV9NY-app]'
});
/* eslint-enable*/
